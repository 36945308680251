import MainLayout from "@/layouts/Main.layout"
import { DashboardApp } from "@/pages/dashboard"
import { EmpresasRoutes } from "@/pages/empresas/routes"
import { SurveyInfoPage } from "@/pages/interactions/components/SurveyInfo"
import { InteractionsPage } from "@/pages/interactions/Interactions.page"
import { LessonsRoutes } from "@/pages/lessons/routes/Lessons.routes"
import { LicensesRoutes } from "@/pages/licenses/routes/Licenses.routes"
import { ModulosRoutes } from "@/pages/modules/routes/Modulos.routes"
import { PerfilRoutes } from "@/pages/perfil/routes/Perfil.routes"
import { PremiosRoutes } from "@/pages/premios/routes/Premios.routes"
import { SocialPage } from '@/pages/social/social.page';
import { InteractionsReportsPage } from "@/pages/reports/InteractionsReport.page"
import { LessonsReportsPage } from "@/pages/reports/LessonsReport.page"
import { UsersReportsPage } from "@/pages/reports/UsersReport.page"
import { TermsConditions } from "@/pages/termsConditions/TermsConditions.page"
import { UsuariosRoutes } from "@/pages/usuarios/routes"
import { AppStore } from "@/redux"
import { useSelector } from "react-redux"
import { Navigate, Route, Routes } from "react-router-dom"
import {ImportUsersPage} from "@/pages/import-users/ImportUsersPage.tsx";

export const PrivateRoutes = () => {

    const { companySlug, user } = useSelector((state: AppStore) => ({ ...state.ui, ...state.auth }))

    const isSuperAdmin = user?.roles.find((rol: { name: string }) => rol.name === 'super_admin')

    return (
        <MainLayout >
            <Routes>
                <Route path="/perfil/*" element={<PerfilRoutes />} />
                <Route path="/empresas/*" element={<EmpresasRoutes />} />
                {isSuperAdmin ? <Route path="/licencias/*" element={<LicensesRoutes />} /> : null}
                {companySlug ? <>
                    <Route path="/premios/*" element={<PremiosRoutes />} />
                    <Route path="/dashboard" element={<DashboardApp />} />
                    <Route path="/usuarios/*" element={<UsuariosRoutes />} />
                    <Route path="/importar-usuarios" element={<ImportUsersPage />} />
                    <Route path="/modulos/*" element={<ModulosRoutes />} />
                    <Route path="/lecciones/*" element={<LessonsRoutes />} />
                    <Route path="/interacciones" element={<InteractionsPage />} />
                    <Route path="/reportes/usuarios" element={<UsersReportsPage />} />
                    <Route path="/reportes/lecciones" element={<LessonsReportsPage />} />
                    <Route path="/reportes/interacciones" element={<InteractionsReportsPage />} />
                    <Route path="/interacciones/encuestas/area/:areaId/encuesta/:surveySlug" element={<SurveyInfoPage />} />
                    <Route path="/premios/*" element={<PremiosRoutes />} />
                    <Route path='/social' element={<SocialPage />} />
                    <Route path="/terminos-condiciones/*" element={<TermsConditions />} />
                </> : null
                }
                <Route path='/*' element={<Navigate to="/empresas" />} />
            </Routes>
        </MainLayout>
    )
}