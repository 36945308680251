import { t } from "i18next";
import { FaDownload, FaFileExcel, FaUpload } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useLoadAreas } from "@/hooks";
import { AppStore } from "@/redux";
import { useSelector } from "react-redux";
import { IoIosSettings } from "react-icons/io";
import { useImportUsers } from "@/hooks/useImportUsers";
import { ResultImportUsers, ValidationFileDataError } from "@/models";
import { MdAutorenew } from "react-icons/md";
import { ImportUsersResultModal } from "../empresas/modals/ImportUsersResult.modal";

export const ImportUsersPage = (): JSX.Element => {
  const { companySlug } = useSelector((state: AppStore) => state.ui);
  const [file, setFile] = useState<File | undefined>();
  const [fileName, setFileName] = useState<string | undefined>();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isValidFile, setIsValidFile] = useState<boolean>(false);
  const [usersToProcess, setUsersToProcess] = useState<number | undefined>(
    undefined
  );
  const [errors, setErrors] = useState<ValidationFileDataError[]>([]);
  const [areaSelected, setAreaSelected] = useState<any | undefined>(undefined);
  const { loadAreas, areas } = useLoadAreas(companySlug);
  const { validateTemplate, processUsers, isProcessing, isValidating } =
    useImportUsers(companySlug);
  const [showModalResult, setShowModalResult] = useState<boolean>(false);
  const [results, setResults] = useState<ResultImportUsers | undefined>();

  useEffect(() => {
    loadAreas(-1).catch(console.error);
  }, []);

  const handleDownloadTemplate = () => {
    const link = document.createElement("a");
    link.href =
      "https://static.holasimon.ai/assets/admin/templates/bulk-load-users.xlsx";
    link.click();
    link.remove();
  };

  const handleChooseFile = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(undefined);
    setFileName(undefined);
    setUsersToProcess(undefined);
    setErrors([]);
    setIsValidFile(false);
    setAreaSelected(undefined);
    try {
      const file = e.target.files?.[0];
      if (!file) return;
      setFile(file);
      setFileName(file.name);
    } catch (err) {
      console.error(err);
      toast.error(t("pages.importUsers.errorLoadingFile"));
    }
  };

  const handleValidateFile = async () => {
    if (!file) {
      return;
    }
    const res = await validateTemplate(file);
    setIsValidFile(res.isValid);
    if (res.isValid) {
      setUsersToProcess(res.usersToProcess);
    }
    if (!res.isValid) {
      setErrors(res.errors ?? []);
      toast.error(t("pages.importUsers.errorValidatingFile"));
      return;
    }
  };

  const handleProcessFile = async () => {
    if (!file || !areaSelected) {
      return;
    }
    const res = await processUsers(file, areaSelected);
    if (!res.wasSuccess) {
      if (res) {
        setResults(res);
        setShowModalResult(true);
        return;
      }
      toast.error(t("pages.importUsers.errorProcessingFile"));
      return;
    }
    toast.success(t("pages.importUsers.successProcessingFile"));
    setResults(res);
    setShowModalResult(true);
  };

  return (
    <article className="bg-[#F4F8FE] h-full overflow-auto">
      <div className="bg-functional-el-l sticky top-0 flex items-center justify-between px-14 py-7 mb-4 z-10">
        <div className="w-full">
          <p className="font-bold text-2xl text-[#1E293B]">
            {t("generics.importUsers")}
          </p>
          <p className="font-medium text-[#5F6060]">
            {t("pages.importUsers.importUsersDescription")}
          </p>
        </div>
      </div>

      <div className="w-[90%] mx-auto bg-functional-el-l mt-[50px] p-3 pt-5 pb-5 rounded-xl flex flex-wrap justify-evenly items-center gap-x-[30px] gap-y-[5px]">
        <div className="w-full max-w-[500px] pt-2 pb-2">
          <h3 className="text-black text-lg font-bold text-start">
            {t("pages.importUsers.step1")}
          </h3>
          <div className="flex w-full py-3 mt-[10px] justify-between">
            <div className="flex gap-2 items-center">
              <FaFileExcel className="text-3xl text-black" />
              <p className="text-black text-sm font-bold text-start">
                bulk-load-users.xlsx
              </p>
            </div>
            <button
              className="bg-[#808080] w-[200px] flex items-center gap-3 justify-center h-[50px] rounded-lg hover:bg-gray-800 transition-all duration-300"
              type="button"
              onClick={handleDownloadTemplate}
            >
              <FaDownload className="text-white" />
              <p className="text-white text-sm font-bold text-start">
                {t("pages.importUsers.download")}
              </p>
            </button>
          </div>
          <h3 className="text-black text-lg font-bold text-start mt-[15px]">
            {t("pages.importUsers.step2")}
          </h3>
          <div className="w-full py-3 mt-[10px] justify-between flex">
            <div
              className="w-[45%] h-[50px] bg-gray-300 flex items-center pl-3 text-sm rounded-lg text-gray-500 hover:bg-gray-400 transition-all duration-300 cursor-pointer"
              onClick={handleChooseFile}
            >
              {fileName ? (
                <p className="">{fileName}</p>
              ) : (
                <p>{t("pages.importUsers.selectXlsx")}</p>
              )}
            </div>
            <div className="w-[45%] flex justify-end">
              <button
                className="bg-[#808080] w-[200px] flex items-center gap-3 justify-center h-[50px] rounded-lg hover:bg-gray-800 transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
                type="button"
                onClick={handleValidateFile}
                disabled={
                  file === undefined || fileName === undefined || isValidating
                }
              >
                <FaUpload className="text-white" />
                <p className="text-white text-sm font-bold text-start">
                  {t("pages.importUsers.load")}
                </p>
                {isValidating && (
                  <MdAutorenew className="animate-spin text-[20px]" />
                )}
              </button>
            </div>
            <input
              type="file"
              hidden
              onChange={handleChangeFile}
              accept=".xlsx"
              ref={inputRef}
            />
          </div>
          {fileName && file && isValidFile && (
            <p className="font-bold text-sm text-green-700">
              {t("pages.importUsers.fileSelected").replace("%f", fileName)}
            </p>
          )}
          {!isValidFile && errors && errors.length > 0 && (
            <>
              <h3 className="text-red-500 text-lg font-bold text-start mt-[15px]">
                Errores:
              </h3>
              <ul className="w-full h-auto max-h-[500px] overflow-hidden overflow-y-auto p-3 bg-gray-200 rounded-md mt-2 list-disc list-inside">
                {errors &&
                  errors.length > 0 &&
                  errors.map((error, index: number) => (
                    <>
                      <li
                        key={index}
                        className="text-red-500 text-sm mb-1 font-semibold text-start"
                      >
                        {error.line !== undefined && error.line !== null
                          ? `${t("pages.importUsers.line")} ${error.line} - ${
                              error.error_message
                            }`
                          : error.error_message}
                      </li>
                    </>
                  ))}
              </ul>
            </>
          )}
          {isValidFile && (
            <>
              <h3 className="text-black text-lg font-bold text-start mt-[15px]">
                {t("pages.importUsers.step3")}
              </h3>
              <p className="text-gray-500">
                {t("pages.importUsers.resultProcess").replace(
                  "%u",
                  usersToProcess?.toString() ?? "0"
                )}
              </p>
              <p className="text-black font-bold text-sm">
                * {t("pages.importUsers.selectArea")}
              </p>
              <div className="w-full py-3 mt-[10px] justify-between flex">
                <div className="w-[45%] h-[50px] flex items-center pl-3 text-sm rounded-lg text-gray-500 cursor-pointer">
                  {/* Input select */}
                  <select
                    className="w-full h-full bg-transparenth-12 bg-[#EAEAEA] rounded-lg px-3 mr-2"
                    onChange={(e) => setAreaSelected(e.target.value)}
                    defaultValue={"default"}
                  >
                    <option value="default" disabled>
                      {t("generics.chooseOne")}
                    </option>
                    {areas.map((area: any) => (
                      <option key={area.slug} value={area.slug}>
                        {area.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-[45%] flex justify-end">
                  <button
                    className="bg-primary-l-m w-[200px] flex items-center gap-3 justify-center h-[50px] rounded-lg hover:bg-primary-l-d transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
                    type="button"
                    disabled={
                      areaSelected === undefined ||
                      areaSelected === "default" ||
                      isProcessing
                    }
                    onClick={handleProcessFile}
                  >
                    <IoIosSettings className="text-white" />
                    <p className="text-white text-sm font-bold text-start">
                      {t("pages.importUsers.process")}
                    </p>
                    {isProcessing && (
                      <MdAutorenew className="animate-spin text-[20px]" />
                    )}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="w-full max-w-[500px] pt-4 pb-4 self-start">
          <h3 className="text-black text-lg font-bold text-start">
            {t("pages.importUsers.instructions")}
          </h3>
          <ul className="w-full mt-[10px] list-disc list-inside">
            <li className="text-black text-[15px] font-normal text-start">
              {t("pages.importUsers.instruction1")}
            </li>
            <li className="text-black text-[15px] font-normal text-start">
              {t("pages.importUsers.instruction2")}
            </li>
            <li className="text-black text-[15px] font-normal text-start">
              {t("pages.importUsers.instruction3")}
            </li>
          </ul>
        </div>
      </div>
      {showModalResult && (
        <ImportUsersResultModal
          setShowModalResult={setShowModalResult}
          data={results}
        />
      )}
    </article>
  );
};
