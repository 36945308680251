import { CustomForm, EFormType } from './CustomForm';
import { TabCompany } from '../layout/TabCompany';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

export function Formularios() {
  const { state } = useLocation();

  const [tab, setTab] = useState(1);

  return (
    <div className="w-full">
      <TabCompany companyId={state?.companyId} />

      <div className="bg-white p-3 shadow-md w-[90%] h-fit mx-auto mt-6">
        <div className="w-full flex gap-6 bg-transparent mx-auto rounded-md text-[18px] overflow-hidden justify-center items-center">
          <button
            onClick={() => setTab(1)}
            className={`rounded-lg w-fit px-5 py-1 ${
              tab === 1 ? 'bg-black text-white' : 'text-black bg-gray-100'
            }`}
          >
            Personal
          </button>

          <button
            onClick={() => setTab(2)}
            className={`rounded-lg w-fit px-5 py-1 ${
              tab === 2 ? 'bg-black text-white' : 'text-black bg-gray-100'
            }`}
          >
            Negocio
          </button>
        </div>

        {tab === 1 && <CustomForm form_type={EFormType.Personal} />}

        {tab === 2 && <CustomForm form_type={EFormType.Business} />}
      </div>
    </div>
  );
}
