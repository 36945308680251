import { apiV3FormData } from "@/apis/apiV3"
import { ResultImportUsers, ValidationFileData } from "@/models/importUsers.model"
import { t } from "i18next"
import { useState } from "react"


export const useImportUsers = (companySlug: string) => {
  const [isValidating, setIsValidating] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  const validateTemplate = async (file: File): Promise<ValidationFileData> => {
    const formData = new FormData()
    formData.append('file', file)

    try {
      setIsValidating(true)
      const res = await apiV3FormData.post(`/companies/${companySlug}/users/validation`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      const data = res.data.total_rows
      return {
        isValid: true,
        usersToProcess: data
      }
    }
    catch (err: any) {
      if (err.response.status === 400) {
        const errors = err.response.data.errors
        return {
          isValid: false,
          errors,
        }
      }
      return {
        isValid: false,
        errors: [{
          error_message: t("pages.importUsers.errorValidatingFile")
        }]
      }
    }
    finally {
      setIsValidating(false)
    }
  }

  const processUsers = async (file: File, areaSlug: string): Promise<ResultImportUsers> => {
    const formData = new FormData()
    formData.append('file', file)
    try {
      setIsProcessing(true)
      const res = await apiV3FormData.post(`/companies/${companySlug}/areas/${areaSlug}/users/bulk`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      console.log(res.data)
      return res.data
    }
    catch (err: any) {
      if (err.status === 400) {
        const errors = err.response.data.errors
        return {
          wasSuccess: false,
          results: errors
        }
      }
      return {
        wasSuccess: false,
        results: null
      }
    }
    finally {
      setIsProcessing(false)
    }
  }

  return {
    validateTemplate,
    processUsers,
    isValidating,
    isProcessing
  }
}
