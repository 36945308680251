import {
  QuestionResponseGetSurveys,
  ResponseGetSurveys,
} from './usePersonalForm';
import { SurveyInfoResponse } from '@/pages/interactions/interfaces/survey.interface';
import { createOptionAdapter } from '../adapters/createOptions.adapter';
import { FORM_POSITION, FORM_STATUS } from '@/models/forms.model';
import { SURVEY_CONSTS } from '@/constants/forms';
import { DataForm } from '../pages/CustomForm';
import { apiV2 } from '@/apis/apiV2';
import { useState } from 'react';

export const useBusinessForm = (companySlug: string) => {
  const [surveySlugHook, setSurveySlug] = useState<string | null>(null);

  const createSurvey = async (): Promise<string | null> => {
    try {
      const infoSurvey = {
        title: SURVEY_CONSTS.PERSONAL_FORM_DESCRIPTION,
        description: SURVEY_CONSTS.PERSONAL_FORM_DESCRIPTION,
        status: FORM_STATUS.ACTIVE,
        position: FORM_POSITION.BUSINESS_PROFILE,
        company_slug: companySlug,
      };
      const res = await apiV2.post(`/surveys`, infoSurvey);

      const data: SurveyInfoResponse = res.data;

      if (data.slug) {
        return data.slug;
      }

      return null;
    } catch (err) {
      return null;
    }
  };

  const createOption = async (
    surveySlug: string,
    title: string,
    linkTo: string,
    validationRules: Record<string, unknown>
  ) => {
    const dataAdapter = {
      question_text: title,
      question_type: 'open',
      validation_rules: JSON.stringify(validationRules),
      survey_slug: surveySlug,
      link_to: linkTo,
    };
    try {
      await apiV2.post(`/surveys/${surveySlug}/questions`, dataAdapter);
    } catch (err) {
      console.error('Error creando la opción', err);
    }
  };

  const updateQuestion = async (
    optionId: string,
    title: string,
    linkTo: string,
    validationRules: Record<string, unknown>
  ) => {
    const dataAdapter = {
      question_text: title,
      question_type: 'open',
      validation_rules: JSON.stringify(validationRules),
      survey_slug: surveySlugHook,
      link_to: linkTo,
    };
    try {
      await apiV2.patch(
        `/surveys/${surveySlugHook}/questions/${optionId}`,
        dataAdapter
      );
    } catch (err) {
      console.error('Error creando la opción', err);
    }
  };

  const deleteQuestion = async (optionId: string) => {
    try {
      await apiV2.delete(`/surveys/${surveySlugHook}/questions/${optionId}`);
    } catch (err) {
      console.error('Error eliminando la opción', err);
    }
  };

  const updateSlugPersonalFormCompany = async (formSlug: string) => {
    // 1. Obtener la configuración
    try {
      const res = await apiV2.get(`/companies/${companySlug}`);
      const config = res.data;
      // 2. Agregamos la nueva variable
      config.slug_survey_business = formSlug;
      // 3. Actualizamos la configuración
      await apiV2.patch(`/companies/${companySlug}`, config);
    } catch (err) {
      console.error('Error actualizando el slug', err);
    }
  };

  const getCompanyConfig = async (companySlug: string) => {
    const res = await apiV2.get(`/companies/${companySlug}`);

    if (res.data) {
      return res.data;
    }

    return null;
  };

  const getSurveys = async (company_slug: string): Promise<DataForm[]> => {
    try {
      const res = await apiV2.get(
        `/surveys?company_slug=${company_slug}&position=${FORM_POSITION.BUSINESS_PROFILE}`
      );
      const data: ResponseGetSurveys = res.data;

      if (!data.items || data.items.length === 0) return [];

      const questions = data.items[0].questions;

      if (!Array.isArray(questions) || questions.length === 0) return [];

      const options: DataForm[] = [];

      questions.forEach((question: QuestionResponseGetSurveys) => {
        const questionMapped = createOptionAdapter(question);

        options.push(questionMapped);
      });

      setSurveySlug(data.items[0].slug);

      return options;
    } catch (err) {
      return [];
    }
  };

  return {
    createSurvey,
    updateSlugPersonalFormCompany,
    createOption,
    getCompanyConfig,
    getSurveys,
    deleteQuestion,
    updateQuestion,
    surveySlugHook,
  };
};
