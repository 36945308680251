import { ResultImportUsers } from "@/models";
import { t } from "i18next";

interface Props {
  setShowModalResult: (showModalResult: boolean) => void;
  data?: ResultImportUsers;
}

export const ImportUsersResultModal = ({
  setShowModalResult,
  data,
}: Props): JSX.Element => {
  return (
    <section className="w-full h-full flex flex-col items-center justify-center fixed top-0 left-0 bg-black bg-opacity-50 z-50 pt-4 pb-4 overflow-auto overflow-y-auto">
      <div className="w-full max-w-[800px] p-4 bg-white rounded-lg shadow-lg h-auto">
        <div className="flex flex-col items-center justify-center">
          <h3 className="text-xl font-bold text-black self-start">
            {data?.wasSuccess ? (
              <>
                {t("pages.importUsers.resultSuccess")
                  .replace("%a", data?.areaName ?? "")
                  .replace("%u", data?.usersProcessed?.toString() ?? "")}
              </>
            ) : (
              <>
                {t("pages.importUsers.resultError")
                  .replace("%a", data?.areaName ?? "")
                  .replace("%u", data?.usersProcessed?.toString() ?? "")}
              </>
            )}
          </h3>
          <p className="text-gray-600 text-sm self-start">
            {t("pages.importUsers.resultInfo")}
          </p>
          <div className="w-full h-auto bg-slate-200 px-3 py-5 rounded-lg mt-4 max-h-[400px] overflow-y-auto">
            {data && data.results && Array.isArray(data.results) && (
              <>
                {data.results.map((result) => {
                  return <p className="text-sm text-gray-800">{result}</p>;
                })}
              </>
            )}
          </div>

          <button
            type="button"
            className="bg-primary-l-m text-black text-sm p-3 w-[200px] mt-4 rounded-md hover:bg-primary-l-d transition-all"
            onClick={() => setShowModalResult(false)}
          >
            {t("generics.accept")}
          </button>
        </div>
      </div>
    </section>
  );
};
