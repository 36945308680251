// TODO: i18n
export const USER_FORM_LINKS = [
  {
    value: 'security_user.name',
    label: 'Nombre',
  },
  {
    value: 'security_user.date_of_birth',
    label: 'Fecha de nacimiento',
  },
  {
    value: 'security_user.gender',
    label: 'Género',
  },
  {
    value: 'security_user.last_name_father',
    label: 'Apellido paterno',
  },
  {
    value: 'security_user.last_name_mother',
    label: 'Apellido materno',
  },
  {
    value: 'security_user.level_education',
    label: 'Nivel de educación',
  },
  {
    value: 'security_user.custom',
    label: 'Custom',
  },
];

export const BUSINESS_FORM_LINKS = [
  {
    value: 'company_user.zipcode',
    label: 'Zip Code',
  },
  {
    value: 'company_user.years_of_antiguaty',
    label: 'Años de antiguedad',
  },
  {
    value: 'company_user.number_of_employees',
    label: 'Número de empleados',
  },
  {
    value: 'company_user.monthly_estimated_income',
    label: 'Ingresos mensuales estimados',
  },
  {
    value: 'company_user.business_name',
    label: 'Nombre del negocio',
  },
  {
    value: 'company_user.is_familiar_business',
    label: 'El negocio es familiar?',
  },
  {
    value: 'company_user.has_credit',
    label: 'Tiene credito?',
  },
  {
    value: 'company_user.custom',
    label: 'Custom',
  },
];

export const SURVEY_CONSTS = {
  PERSONAL_FORM_TITLE: 'Formulario Personal',
  PERSONAL_FORM_DESCRIPTION: 'Formulario personal de la empresa'
}
