import { Navigate, Route, Routes } from "react-router-dom"
import { CRUDEmpresas } from "../pages/CRUDEmpresas"
import { EmpresasApp } from "../pages/EmpresasApp"
import { PersonalizationEmpresas } from "../pages/PersonalizationEmpresas"
import { Areas } from "../pages/Areas"
import { LicensesCompany } from "../pages/LicensesCompany"
import { Formularios } from "../pages/Formularios"

export const EmpresasRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<EmpresasApp />} />
            <Route path="crud" element={<CRUDEmpresas />} />
            <Route path="personalizar" element={<PersonalizationEmpresas />} />
            <Route path="areas" element={<Areas />} />
            <Route path="Licencias" element={<LicensesCompany />} />
            <Route path="formularios" element={<Formularios />} />
            <Route path="/*" element={<Navigate to='/empresas' />} />
        </Routes>
    )
}
