import { QuestionResponseGetSurveys } from '../hooks';
import { DataForm } from '../pages/CustomForm';

export function createOptionAdapter(
  question: QuestionResponseGetSurveys
): DataForm {
  const validateRules: OptionsJSON = JSON.parse(question.validation_rules);

  const option = {
    id: question.id,
    title: question.question_text,
    linkTo: question.link_to ?? '',
    type: validateRules.typeInput ?? '',
    isRequired: validateRules.required ?? false,
    negativeNumbers: validateRules.allowNegativeNumbers ?? false,
  };

  return option as DataForm;
}

export interface OptionsJSON {
  required?: boolean;
  allowNegativeNumbers?: boolean;
  typeInput?: string;
}
